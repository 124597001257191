import { Avatar } from "@/components/Avatar";
import { GamePlayerTeamJoined } from "@/components/GamePlayerTeamJoined";
import {
    ArrowRightUnfilled,
    ClockUnfilled,
    LocationUnfilled,
    UsersUnfilled,
} from "@/components/Icons";
import { Tag } from "@/components/Tag";
import dayjs from "@/lib/dayjs";
import type { Game } from "@/lib/hooks/usePublicGames";
import { cx } from "class-variance-authority";
import Link from "next/link";
import { useTranslation } from "react-i18next";

export const GameCard = ({
    game,
    showFilter,
}: {
    game: Game;
    showFilter: boolean;
}): JSX.Element => {
    const { t } = useTranslation(["components/GameCard", "common"]);

    const startDt = dayjs(game.startDt);
    const endDt = dayjs(game.endDt);
    const start = startDt.format("DD MMM YYYY, dddd");
    const end = endDt.format("DD MMM YYYY, dddd");
    const date = start === end ? start : `${start} - ${end}`;
    const time = `${startDt.format("h:mm A")} - ${endDt.format("h:mm A")}`;

    const numberOfPlayers = game.players.length + (game.reservedPlayers || 0);

    const players = game.players
        .filter((player) => player.displayPicture != null)
        .map((player) => ({
            displayPicture: player.displayPicture || "",
        }));

    const text =
        numberOfPlayers >= game.maxPlayers
            ? t("gameIsFull", "Game is full")
            : t(
                  "gameLookingFor",
                  "Looking for another {{count}} {{context}}(s)",
                  {
                      context: game.playersOrTeams,
                      count: game.maxPlayers - numberOfPlayers,
                  },
              );

    return (
        <Link href={`/game/${game.uid}`}>
            <div
                className={cx(
                    "flex h-full cursor-pointer flex-col justify-between rounded-xl border border-solid border-blue-grey-50 bg-white p-4",
                    !showFilter && "flex-1",
                )}
            >
                <div className="grid grid-cols-[36px_minmax(0,_auto)] gap-4">
                    <Avatar
                        src={players[0]?.displayPicture}
                        text={game.organizerName.charAt(0)}
                        size="32"
                    />
                    <div className="flex flex-col">
                        <Tag className="typography-micro mb-1.5 w-fit bg-brand-light font-bold uppercase text-blue-grey-900">
                            {game.categoryName}
                        </Tag>
                        <span className="typography-overline-lg truncate text-nowrap text-primary-600">
                            {game.name}
                        </span>
                        <span className="typography-tiny line-clamp-1 text-blue-grey-600">
                            {t("hostedBy", "Hosted by {{organizerName}}", {
                                organizerName: game.organizerName,
                            })}
                        </span>
                    </div>
                    <span className="flex size-8 items-center justify-center rounded-full bg-[#F7F9FF]">
                        <LocationUnfilled className="text-primary-600" />
                    </span>
                    <div className="typography-sub flex flex-col text-blue-grey-900">
                        <span className="line-clamp-1 font-bold">
                            {game.location?.name}
                        </span>
                        <span className="line-clamp-1">{game.details}</span>
                    </div>
                    <span className="flex size-8 items-center justify-center rounded-full bg-[#F7F9FF]">
                        <ClockUnfilled className="text-primary-600" />
                    </span>
                    <div className="typography-sub flex flex-col text-blue-grey-900">
                        <span className="font-bold">{date}</span>
                        <span>{time}</span>
                    </div>
                    <span className="flex size-8 items-center justify-center rounded-full bg-[#F7F9FF]">
                        <UsersUnfilled className="text-primary-600" />
                    </span>
                    <div className="typography-sub flex flex-col gap-3 text-blue-grey-900">
                        <div className="flex flex-col">
                            <span className="font-bold">
                                {t("gameIsOn", "Game is on!")}
                            </span>
                            <span>{text}</span>
                        </div>
                        <GamePlayerTeamJoined
                            players={players}
                            hostInitials={game.organizerName.charAt(0)}
                            totalJoinedPlayers={numberOfPlayers}
                            maxPlayers={game.maxPlayers}
                        />
                    </div>
                </div>
                <div className="typography-h5 mt-4 flex items-center justify-end gap-2 font-semibold text-primary-600">
                    {t("common:view_more", "View more")}
                    <span className="flex size-6 items-center justify-center rounded-full bg-blue-grey-50">
                        <ArrowRightUnfilled className="size-3 text-primary-600" />
                    </span>
                </div>
            </div>
        </Link>
    );
};
