import { useTranslation } from "react-i18next";
import { Avatar } from "./Avatar";

type GamePlayerTeamJoinedProps = {
    players: { displayPicture: string }[];
    hostInitials: string;
    totalJoinedPlayers: number;
    maxPlayers: number;
};
export const GamePlayerTeamJoined = ({
    players,
    hostInitials,
    totalJoinedPlayers,
    maxPlayers,
}: GamePlayerTeamJoinedProps): JSX.Element => {
    const { t } = useTranslation("components/GamePlayerTeamJoined");
    const maxDisplay = 6;
    const displayPlayer = players.slice(0, maxDisplay);
    while (displayPlayer.length < maxPlayers - 1) {
        displayPlayer.push({ displayPicture: "" });
        if (displayPlayer.length >= maxDisplay) break;
    }

    return (
        <div className="flex items-center py-0.5">
            <div className="grid auto-cols-[16px] grid-flow-col">
                {displayPlayer.map((p, i) => (
                    <Avatar
                        key={p.displayPicture}
                        src={p.displayPicture}
                        index={i}
                        size="24.5"
                        className="col-span-2"
                        text={i === 0 ? hostInitials : undefined}
                    />
                ))}
                {players.length < maxPlayers && (
                    <Avatar
                        index={displayPlayer.length}
                        text={maxPlayers}
                        size="24.5"
                        className="col-span-2"
                    />
                )}
            </div>
            <span className="pl-3 text-tiny text-blue-grey-400">
                {t("playersJoined", {
                    count: totalJoinedPlayers,
                    max: maxPlayers,
                })}
            </span>
        </div>
    );
};
