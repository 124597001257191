import type { ApolloError } from "@apollo/client";

// TODO: Revisit "store reset query was in flight" error, this currently comes from us using `client.resetStore()`
// We may opt for `clearStore()`, however this will not refetch any active queries, would have to verify this behavior.
// See: https://www.apollographql.com/docs/react/api/core/ApolloClient/#clearstore
// E.g On Centre Booking Select Page, if user has membership services, once the user logs out
// we should refetch query on the page so membership services are no longer selectable.
export const hasQueryLoadFail = (error: ApolloError): boolean =>
    error.message === "Load failed" ||
    error.message === "Failed to fetch" ||
    error.message === "cancelled" ||
    error.message ===
        "Store reset while query was in flight (not completed in link chain)";

// Check if the error code is 'UNAUTHORISED'.
export const isUnauthorised = (error?: ApolloError): boolean =>
    error?.graphQLErrors.some(
        (err) => err.extensions?.code === "UNAUTHORISED",
    ) ?? false;

// Possible cases to trigger:
// (1) workflow no longer exsit on temporal
// (2) workflowId is malformed
export const isWorkflowMissing = (
    type: "checkout" | "paymentLink" | "reschedule",
    error?: ApolloError,
): boolean => {
    let message = "checkout not found";
    if (type === "paymentLink") message = "capture payment workflow not found";
    else if (type === "reschedule")
        message = "courtsite reschedule workflow not found";
    return (
        error?.graphQLErrors.some((e) => e.message.includes(message)) ?? false
    );
};
