import { ReattemptBookingCard } from "@/components/BookingCard";
import BookingList from "@/components/BookingList/BookingList";

type BookingUnconfirmedHomePageProps = {
    unconfirmedBookings: {
        uid: string;
        tenantId: string;
        referenceId: string;
        created: string;
        startDt: string;
        endDt: string;
        confirmed?: string | null;
        cancelled?: string | null;
        resources: {
            uid: string;
            resource: { uid: string; name: string; archived?: string | null };
        }[];
        service?: {
            uid: string;
            serviceMode: string;
            category?: { uid: string; name: string } | null;
        } | null;
        metadata: { price?: string; workflowId?: string; invoiceId?: string };
    }[];
    isDesktop: boolean;
};
const BookingUnconfirmedHomePage = ({
    unconfirmedBookings,
    isDesktop = true,
}: BookingUnconfirmedHomePageProps): JSX.Element => {
    const groupedUnconfirmedBookings =
        BookingList.groupBookings(unconfirmedBookings);

    if (isDesktop) {
        return (
            <div className="fixed right-0 top-[90] z-10 m-4 flex flex-col gap-3">
                {groupedUnconfirmedBookings.map((booking) => (
                    <ReattemptBookingCard
                        key={booking.referenceId}
                        booking={booking}
                        href={getHref(booking)}
                    />
                ))}
            </div>
        );
    }

    return (
        <div className="fixed bottom-16 z-10 w-full">
            <div className="flex w-full flex-col gap-3 px-2">
                {groupedUnconfirmedBookings.map((booking) => (
                    <ReattemptBookingCard
                        key={booking.referenceId}
                        booking={booking}
                        href={getHref(booking)}
                    />
                ))}
            </div>
        </div>
    );
};

export default BookingUnconfirmedHomePage;

type GroupedBookings = ReturnType<typeof BookingList.groupBookings>[0];
const getHref = (b: GroupedBookings): string => {
    const { workflowType } = b.metadata;
    const checkoutHref =
        workflowType === "CourtsiteRescheduleWorkflow"
            ? `reschedule/checkout/${b.metadata.workflowId}`
            : `/checkout/${b.metadata.workflowId}/payment`;
    return b.metadata.invoiceId
        ? `/invoice/${b.tenantId}/${b.metadata.invoiceId}`
        : checkoutHref;
};
