import { middot } from "@/assets/middot";
import { Badge, type BadgeProps } from "@/components/Badge";
import { Button } from "@/components/Button";
import { Skeleton } from "@/components/Skeleton";
import { tzKL } from "@/lib/date-fns-util";
import { graphql } from "@/lib/gql";
import {
    hasQueryLoadFail,
    isUnauthorised,
    isWorkflowMissing,
} from "@/utils/sentry";
import { useQuery } from "@apollo/client";
import { TZDate } from "@date-fns/tz";
import { captureException } from "@sentry/nextjs";
import {
    addMinutes,
    differenceInSeconds,
    format,
    getUnixTime,
    parseISO,
} from "date-fns";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Chip } from "../Chip";
import { ClockUnfilled } from "../Icons";

const activePayableTimeInSeconds = 480;
const getDiffInSecs = (endDt: Date): number =>
    differenceInSeconds(endDt, new Date());

const reattemptBookingCardQuery = graphql(`
    query reattemptBookingCard($tenantId: ID!) {
        organisation(uid: $tenantId) {
            uid
            name
        }
    }
`);

const checkoutStateByIdQuery = graphql(`
    query reattemptBookingCardCheckoutState($workflowId: ID!) {
        courtsiteCheckoutStateById(checkoutId: $workflowId) {
            id
            created
            expired
            completed
            cancelled
            tenantId
            bookingIds
            capturePaymentState {
                id
                state
                completed
                expired
                cancelled
                ...PaymentHandlerData
            }
        }
    }
`);

type BookingStatus =
    | "CANCELLED"
    | "PAST"
    | "ONGOING"
    | "UNCONFIRMED"
    | "FUTURE";
type BookingBadge = { color: BadgeProps["color"]; label: string };

type Booking = {
    tenantId: string;
    categoryName?: string;
    start: string;
    end: string;
    cancelled?: string | null;
    confirmed?: string | null;
    courtNames: string;
    isDailyService: boolean;
    metadata: {
        invoiceId?: string;
        workflowId?: string;
        workflowType?: string;
    };
};
type ReattemptBookingCardProps = {
    booking: Booking;
    href?: string;
};
const ReattemptBookingCard = ({
    booking,
    href,
}: ReattemptBookingCardProps): JSX.Element => {
    const router = useRouter();
    const [secondsRemaining, setSecondsRemaining] = useState(
        activePayableTimeInSeconds,
    );
    const { t, ready } = useTranslation(["components/BookingCard", "common"]);
    const { workflowId, workflowType } = booking.metadata;

    const { data, loading } = useQuery(reattemptBookingCardQuery, {
        variables: { tenantId: booking.tenantId },
    });
    const { data: workflowData, loading: workflowLoading } = useQuery(
        checkoutStateByIdQuery,
        {
            fetchPolicy: "network-only",
            skip: !workflowId || workflowType !== "CourtsiteCheckoutWorkflow",
            pollInterval: 5000,
            variables: workflowId ? { workflowId } : undefined,
            onError: (error) => {
                if (hasQueryLoadFail(error)) return;

                if (
                    isUnauthorised(error) ||
                    isWorkflowMissing("checkout", error)
                ) {
                    router.replace("/");
                    return;
                }

                const customError = new Error(error.message);
                customError.name = "Checkout Paymet Workflow Error";
                captureException(customError, (ctx) =>
                    ctx.setExtras({
                        request: { workflowId },
                        errorMessage: error.message,
                        graphqlErrors: error.graphQLErrors,
                        networkErrors: error.networkError,
                        extraInfo: error.extraInfo,
                        queryName: "pelangganCheckoutWorkflowPayment",
                        error: error,
                    }),
                );
            },
        },
    );

    const workflow = workflowData?.courtsiteCheckoutStateById;
    const expiryDate = workflow && addMinutes(parseISO(workflow.created), 8);

    const tagContent: Record<BookingStatus, BookingBadge> = {
        CANCELLED: {
            color: "red",
            label: t("common:tagsv2_cancelled", "CANCELLED"),
        },
        PAST: {
            color: "light",
            label: t("tags.past", "PAST BOOKING"),
        },
        ONGOING: {
            color: "success",
            label: t("tags.ongoing", "ACTIVE BOOKING"),
        },
        FUTURE: {
            color: "dark",
            label: t("tags.future", "UPCOMING BOOKING"),
        },
        UNCONFIRMED: {
            color: "red",
            label: t("tags.unconfirmed", "PENDING PAYMENT"),
        },
    };

    useEffect(() => {
        if (!expiryDate) return;
        const id = setInterval(
            () => setSecondsRemaining(getDiffInSecs(expiryDate)),
            1000,
        );
        return () => clearInterval(id);
    }, [expiryDate]);

    if (loading || !data || !ready || workflowLoading) {
        return (
            <div className="relative flex w-full flex-col gap-1 rounded-md border border-blue-grey-50 bg-white p-3 shadow-md">
                <div className="flex flex-col gap-4">
                    <div className="flex justify-between">
                        <div className="h-4 w-1/4 animate-pulse rounded bg-blue-grey-50" />
                        <div className="h-6 w-1/4 animate-pulse rounded bg-blue-grey-50" />
                    </div>
                    <Skeleton paragraph={{ rows: 2 }} />
                    <div className="grid grid-cols-1">
                        <div className="h-[29px] w-full animate-pulse rounded bg-blue-grey-50" />
                    </div>
                </div>
            </div>
        );
    }

    const now = getUnixTime(new Date());
    const start = new TZDate(new Date(booking.start), tzKL);
    const end = new TZDate(new Date(booking.end), tzKL);

    const bookingStatus = getBookingStatus(booking, end, now, start);

    let startFmt = "hh:mma";
    let endFmt = "hh:mma";
    if (booking.isDailyService) {
        startFmt = "dd/MM/yy";
        endFmt = "dd/MM/yy";
    }
    let timeTxt = `${format(start, startFmt)} - ${format(end, endFmt)}`;
    if (!booking.isDailyService)
        timeTxt = format(start, "dd/MM/yy") + middot + timeTxt;
    const { color, label } = tagContent[bookingStatus];

    // TODO: Add cancel button
    const counter = Math.max(secondsRemaining, 0);
    const formattedCounter = `${Math.floor(counter / 60)}:${(counter % 60).toString().padStart(2, "0")}`;
    const actionBtn = (
        <div className="grid grid-cols-1 gap-2">
            {href && (
                <Link
                    href={href}
                    data-attr="continue-checkout-payment"
                    passHref
                >
                    <Button size="sm">
                        {t("common:buttonPayNow", "Pay Now")}
                    </Button>
                </Link>
            )}
        </div>
    );

    return (
        <div className="flex w-full flex-col gap-4 rounded-xl bg-white p-3 shadow-lg lg:max-w-[35vw]">
            <div className="flex flex-col gap-2">
                <div className="flex flex-col">
                    <div className="flex justify-between">
                        <Badge color={color}>{label}</Badge>
                        <Chip className="flex gap-0.5 bg-destructive-50 !py-0 uppercase text-destructive">
                            <ClockUnfilled className="size-4" />
                            <span className="font-bold">
                                {t("counterChip", "{{formattedCounter}} left", {
                                    formattedCounter,
                                })}
                            </span>
                        </Chip>
                    </div>
                    <span className="typography-tiny font-bold uppercase text-primary">
                        {booking.categoryName}
                    </span>
                    <div className="typography-main font-bold text-blue-grey-900">
                        {data.organisation.name}
                    </div>
                </div>
                <div className="typography-sub text-blue-grey">
                    <div>{timeTxt}</div>
                    <div>{booking.courtNames}</div>
                </div>
            </div>
            {actionBtn}
        </div>
    );
};

export default ReattemptBookingCard;

function getBookingStatus(
    booking: Booking,
    end: Date,
    now: number,
    start: Date,
): "CANCELLED" | "PAST" | "ONGOING" | "UNCONFIRMED" | "FUTURE" {
    if (booking.cancelled) return "CANCELLED";
    if (!booking.confirmed) return "UNCONFIRMED";
    if (getUnixTime(end) < now) return "PAST";
    if (getUnixTime(start) < now) return "ONGOING";
    return "FUTURE";
}
